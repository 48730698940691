
import {Vue} from "vue-class-component";
import {useSessionStore, useSyncStore} from "@/store";
import {StatsService} from "@/services/stats-service";
import {ItemsStatsAggregated, ItemsStatsPerPeriod, TimePeriod} from "@/models/stats-models";
import {format, parseISO} from "date-fns";

export default class StatsPerPeriod extends Vue {
  authorized: Boolean = false
  loadedData: boolean = false

  sessionStore = useSessionStore()
  statsService = new StatsService()
  syncStore = useSyncStore()

  itemsStats: ItemsStatsPerPeriod[] = [];

  isAuthorized() {
    this.authorized = this.sessionStore.isAuthorized
  }

  mounted() {
    this.loadedData = false

    this.isAuthorized()

    this.sessionStore.$subscribe((mutation, state) => {
      this.authorized = state.authorized
    })

    this.syncStore.$subscribe(() => {
      console.debug(`Got sync store change - reloading component data`)
      this.loadStats()
    })
  }

  displayPeriod(period: TimePeriod): String {
    return format(parseISO(period.begin.toString()), "dd-MM-yyyy") + " - " + format(parseISO(period.end.toString()), "dd-MM-yyyy");
  }

  loadStats() {
    let self = this

    this.statsService.getPeriodStats()
        .then((responseData: ItemsStatsAggregated) => {
          self.itemsStats = responseData.itemsStats
          self.loadedData = true
        });
  }
}
