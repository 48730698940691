
import {Vue} from "vue-class-component";
import {useSessionStore} from "@/store";
import {AuthorizationService} from "@/services/authorization-service";

export default class TopAuth extends Vue {
  waitingForLogin: Boolean = false
  authorized: Boolean = false

  showLoginButton:Boolean = false
  loginUrl: String = ""

  sessionStore = useSessionStore()

  isAuthorized() {
    this.authorized = this.sessionStore.isAuthorized;
    this.onAuthorizationStatusChanged();
  }

  onAuthorizationStatusChanged() {
    if (!this.showLoginButton) {
      let authService = new AuthorizationService()

      let self = this
      authService.getLoginUrl().then((response) => {
        self.loginUrl = response.data.data.link
        self.showLoginButton = true
      });
    }
  }

  loginStarted() {
    this.waitingForLogin = true
    let authService = new AuthorizationService()

    let self = this
    authService.waitForAuthorization().then((value) => {
      self.waitingForLogin = false
      self.authorized = value
    });
  }

  mounted() {
    this.authorized = false
    this.waitingForLogin = false

    this.isAuthorized()

    let self = this
    this.sessionStore.$subscribe((mutation, state) => {
      console.info("store auth status")
      self.authorized = state.authorized
      self.onAuthorizationStatusChanged()
    })
  }
}
