import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card"
}
const _hoisted_2 = { class: "card-body" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { style: {"width":"100%"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_apexchart = _resolveComponent("apexchart")!

  return (_ctx.authorized && _ctx.loadedData)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h5", _hoisted_3, _toDisplayString(_ctx.header), 1),
          _createElementVNode("div", _hoisted_4, [
            _createVNode(_component_apexchart, {
              options: _ctx.options,
              series: _ctx.series,
              type: "heatmap"
            }, null, 8, ["options", "series"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}