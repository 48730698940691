import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card"
}
const _hoisted_2 = {
  key: 0,
  class: "card-body"
}
const _hoisted_3 = { class: "card-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_badge = _resolveComponent("b-badge")!

  return (_ctx.authorized)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        (_ctx.loadedData)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("h5", _hoisted_3, "Top " + _toDisplayString(_ctx.limitTags) + " the most popular tags.", 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.topTags, (item) => {
                return (_openBlock(), _createElementBlock("span", {
                  key: item.name,
                  class: "badge"
                }, [
                  _createVNode(_component_b_badge, { variant: "dark" }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.name) + " ", 1),
                      _createVNode(_component_b_badge, { variant: "light" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(item.count), 1)
                        ]),
                        _: 2
                      }, 1024)
                    ]),
                    _: 2
                  }, 1024)
                ]))
              }), 128))
            ]))
          : _createCommentVNode("", true)
      ]))
    : _createCommentVNode("", true)
}