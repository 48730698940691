
import {Options, Vue} from 'vue-class-component';
import DailyStatsChart from "@/components/DailyStatsChart.vue";
import TopTools from "@/components/SyncPanel.vue";
import TopTags from "@/components/TopTags.vue";
import LangStats from "@/components/LangStats.vue";
import StatsPerPeriod from "@/components/StatsPerPeriod.vue";
import HeatmapArchivedChart from "@/components/HeatmapArchivedChart.vue";
import {HeatmapType} from "@/models/stats-models";

@Options({
  computed: {
    HeatmapType() {
      return HeatmapType
    }
  },
  components: {
    LangStats,
    TopTags,
    DailyStatsChart,
    TopTools,
    StatsPerPeriod,
    HeatmapArchivedChart
  },
})
export default class HomeView extends Vue {
}
